import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Button from "../Button"
import { FormControl } from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import StatusTabs from "./StatusTabs";
import PromoTable from "./PromoTable";

const SearchablePromos = props => {
  const {
    promos,
    doFetchGetPromo,
    doFetchUpdatePromo,
    adminUser,
  } = props

  const [search, setSearch] = useState("")

  useEffect(() => {
    doFetchGetPromo({ email: search })
  }, []);

  const handleConfirm = e => {
    e.preventDefault()
    doFetchGetPromo(search)
  }

  const convertToCSV = (data) =>{
    // make a copy of the data
    const exportData = [
      ...data,
    ]
    // update created with formatted date
    exportData.forEach(x => {
      x.created = moment(x.created).format("MM/DD/YYYY")
    })
    
    const headers = [
      {
        Header: "Promo ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Submitted Date",
        accessor: "created",
      },
      {
        Header: "Promo Type",
        accessor: "promo_type",
      },
      {
        Header: "Meter Identifier",
        accessor: "meter_identifier",
      },
      {
        Header: "Meter Identifier 2",
        accessor: "meter_identifier2",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Status Reason",
        accessor: "status_reason",
      },
    ]
    const header = headers.map(x => x.Header).join(",");
    const values = exportData.map(x => headers.map(y => x[y.accessor]).join(","));
    const csv = [header, ...values].join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const encodedURI = encodeURI(csvContent);
    window.open(encodedURI);
  }

  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          <FormControl style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Grid container>
              <Grid item xs={3} style={{ marginRight: "30px" }}>
                <div style={{ marginBottom: '-5px' }}><label>Email</label></div>
                <TextField
                  value={search ? search : ""}
                  type="text"
                  onChange={e => setSearch(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginRight: "30px" }}>
                <div>
                  <Button
                    autoFocus
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "10px" }}
                  >
                    Search
                  </Button>
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Button
        color="primary"
        variant="contained"
        onClick={() => convertToCSV(promos)}
        style={{ float: "right", marginBottom: "5px" }}
      >
        EXPORT
      </Button>
      <StatusTabs
        viewNames={["All Promos", "Pending Review"]}
      >
        <PromoTable
          promos={promos}
          doFetchUpdatePromo={doFetchUpdatePromo}
          adminUser={adminUser}
        />
        <PromoTable
          promos={promos}
          doFetchUpdatePromo={doFetchUpdatePromo}
          adminUser={adminUser}
          statusFilter="pending_review"
        />
      </StatusTabs>
    </div>
  )
}

export default SearchablePromos;
