import { createSelector } from "reselect";

const communitySolar = () => (state) => state.communitySolar;

const selectCommunitySolarContainer = () =>
  createSelector(communitySolar(), (data) => {
    return data;
  });

export default selectCommunitySolarContainer;
