import React from "react";
import { connect } from "react-redux";
import CommunitySolar from "../components/CommunitySolar";
import selectCommunitySolarsContainer from "../selectors/communitySolar";
import {
  getApprovals,
  getDetails,
  updateMeter,
  openMemberDocument,
} from "../actions/communitySolar";

const CommunitySolarContainer = (props) => <CommunitySolar {...props} />;

const mapStateToProps = (state) => ({
  ...selectCommunitySolarsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetApprovals: () => {
    dispatch(getApprovals());
  },
  doFetchGetDetails: memberID => {
    dispatch(getDetails(memberID));
  },
  doFetchUpdateMeter: payload => {
    dispatch(updateMeter(payload));
  },
  doOpenDocument: (memberID, documentID) => {
    dispatch(openMemberDocument(memberID, documentID));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySolarContainer);
