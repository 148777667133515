import React, { useState } from "react";
import { useStyles } from "./styles";
import StatusOverlay from "../StatusOverlay"
import CommunitySolarTabs from "./CommunitySolarTabs";
import LmiApproval from "./LmiApproval";
import EnrollmentReview from "./EnrollmentReview";

const CommunitySolar = (props) => {
  const classes = useStyles();
  const {
    approvals,
    lmiTypes,
    doFetchGetApprovals,
    doFetchGetDetails,
    approval,
    documents,
    doFetchUpdateMeter,
    doOpenDocument,
    adminUser,
  } = props;

  const [selectedView, setSelectedView] = useState("Search Enrollments");
  return (
    <div className={classes.root}>
      <StatusOverlay />
      <h1 id="page-title">
        Community Solar
      </h1>
      <CommunitySolarTabs
        setSelectedView={setSelectedView}
      >
        <LmiApproval
          adminUser={adminUser}
          selectedView={selectedView}
          approvals={approvals}
          lmiTypes={lmiTypes}
          doFetchGetApprovals={doFetchGetApprovals}
          doFetchGetDetails={doFetchGetDetails}
          approval={approval}
          documents={documents}
          doFetchUpdateMeter={doFetchUpdateMeter}
          doOpenDocument={doOpenDocument}
        />
        <EnrollmentReview
          adminUser={adminUser}
          selectedView={selectedView}
          approvals={approvals}
          lmiTypes={lmiTypes}
          doFetchGetApprovals={doFetchGetApprovals}
          doFetchGetDetails={doFetchGetDetails}
          approval={approval}
          documents={documents}
          doFetchUpdateMeter={doFetchUpdateMeter}
          doOpenDocument={doOpenDocument}
        />
      </CommunitySolarTabs>
    </div>
  );
};

export default CommunitySolar;
